window.addEventListener('load', function () {
    /**
     * Blue Bar Block Code Starts
     */
    const blue_bar_block_svg = document.querySelectorAll('.blue-bar-block svg')

    if (blue_bar_block_svg.length > 0) {
        Array.from(blue_bar_block_svg).forEach(function (element) {
            element.addEventListener('mouseenter', function (event) {
                event.target.innerHTML = '<path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>'
            })
            element.addEventListener('mouseleave', function (event) {
                event.target.innerHTML = '<path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>'
            })
        })
    }
    /**
     * Blue Bar Block Code Ends
     */

    /**
     * Carousel Block Code Starts
     */
    const carousel_splide_type1 = document.querySelectorAll('.splide.type1')

    if (carousel_splide_type1.length > 0) {

        for (var i = 0; i < carousel_splide_type1.length; i++) {
            new Splide(carousel_splide_type1[i], {
                type: 'loop',
                easing: 'ease-in-out',
                speed: 600,
                interval: 6000,
                rewind: false,
                width: '100%',
                heightRatio: 0.28,
                arrows: false,
                pagination: true,
                drag: false,
                autoplay: true
            }).mount()
        }

    }

    const carousel_splide_type2 = document.querySelectorAll('.splide.type2');

    if (carousel_splide_type2.length > 0) {

        for (var i = 0; i < carousel_splide_type2.length; i++) {
            new Splide(carousel_splide_type2[i], {
                type: 'loop',
                easing: 'ease-in-out',
                speed: 600,
                rewind: false,
                width: '100%',
                arrows: true,
                pagination: false,
                drag: false,
                autoHeight: true
            }).mount()
        }

    }
    /**
     * Carousel Block Code Ends
     */

    /**
     * Team List Block Code Starts
     */

    const team_list_block = document.querySelector('.team-list-block');

    if (typeof (team_list_block) != 'undefined' && team_list_block != null) {
        const expertiseSelect = team_list_block.querySelector('#expertise')
        const locationSelect = team_list_block.querySelector('#location')
        const teamMembers = team_list_block.querySelectorAll('.team-list-block h3')
        const inner = team_list_block.getElementsByClassName('inner')

        // Function to show/hide team members based on user selection
        function filterTeamMembers() {
            const selectedExpertise = expertiseSelect.value
            const selectedLocation = locationSelect.value

            teamMembers.forEach((member) => {
                // Parse the data-expertise attribute
                const expertiseString = member.dataset.expertise || ''
                const expertise = expertiseString.split(',')

                // Parse the data-location attribute
                const locationString = member.dataset.location || ''
                const location = locationString.split(',')

                // Check if the selected options match any of the team member's expertise and location
                const expertiseMatch = selectedExpertise === 'all' || expertise.includes(selectedExpertise)
                const locationMatch = selectedLocation === 'all' || location.includes(selectedLocation)

                if (expertiseMatch && locationMatch) {
                    member.classList.remove('hidden') // Show the team member
                    member.classList.add('block') // Show the team member
                } else {
                    member.classList.add('hidden') // Hide the team member
                    member.classList.remove('block') // Hide the team member
                }
            })

            Array.from(inner).forEach((inn) => {
                var innerHeading = inn.querySelectorAll('h3.block')
                if (innerHeading.length == 0) {
                    inn.classList.add('hidden')
                    inn.classList.remove('block')
                    inn.closest('.main').classList.add('hidden')
                }
                else {
                    inn.classList.remove('hidden')
                    inn.classList.add('block')
                    inn.closest('.main').classList.remove('hidden')
                }
                var mainBlock = inn.closest('.main').querySelectorAll('.inner.block')
                if (mainBlock.length > 0) {
                    inn.closest('.main').classList.remove('hidden')
                }
            })
        }

        // Add event listeners to the select elements
        expertiseSelect.addEventListener('change', filterTeamMembers)
        locationSelect.addEventListener('change', filterTeamMembers)
    }

    /**
     * Team List Block Code Ends
     */

    /**
     * Three Column Info Block Code Starts
     */

    const three_col_info = document.querySelectorAll('.three-col-info-block.type2 .inner-column')

    if (three_col_info.length > 0) {
        Array.from(three_col_info).forEach(function (element) {
            element.addEventListener('mouseenter', function (event) {
                const svgs = event.target.querySelectorAll('svg path')
                Array.from(svgs).forEach(function (element) {
                    element.outerHTML = '<path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>'
                })
            })
            element.addEventListener('mouseleave', function (event) {
                const svgs = event.target.querySelectorAll('svg path')
                Array.from(svgs).forEach(function (element) {
                    element.outerHTML = '<path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>'
                })
            })
        })
    }

    /**
     * Three Column Info Block Code Ends
     */

    /**
     * Timeline Block Code Starts
     */

    const timeline_block = document.querySelector('.timeline-block')

    if (typeof (timeline_block) != 'undefined' && timeline_block != null) {
        const timeline = timeline_block.querySelector('.in-grid')
        const timelineContainer = timeline_block.querySelector('.timelineContainer')
        const left_scroll_timeline = timeline_block.querySelector('.scroll-left')
        const right_scroll_timeline = timeline_block.querySelector('.scroll-right')

        left_scroll_timeline.addEventListener("click", function (event) {
            event.preventDefault()
            var scrollDistance
            if (window.innerWidth < 576) {
                scrollDistance = timelineContainer.offsetWidth / 2
            }
            else if (window.innerWidth >= 576 && window.innerWidth < 992) {
                scrollDistance = timelineContainer.offsetWidth / 3
            }
            else {
                scrollDistance = timelineContainer.offsetWidth / 6
            }
            timeline.scrollLeft -= scrollDistance
        })
        right_scroll_timeline.addEventListener("click", function (event) {
            event.preventDefault()
            var scrollDistance
            if (window.innerWidth < 576) {
                scrollDistance = timelineContainer.offsetWidth / 2
            }
            else if (window.innerWidth >= 576 && window.innerWidth < 992) {
                scrollDistance = timelineContainer.offsetWidth / 3
            }
            else {
                scrollDistance = timelineContainer.offsetWidth / 6
            }
            timeline.scrollLeft += scrollDistance
        })

        function changeIconState() {
            const scrollableWidth = timeline.scrollWidth - timeline.clientWidth
            if (timeline.scrollLeft >= scrollableWidth) {
                right_scroll_timeline.querySelector("svg").classList.remove("fill-cb-sea-green")
                right_scroll_timeline.querySelector("svg").classList.add("fill-cb-faded-text")
            }
            else {
                right_scroll_timeline.querySelector("svg").classList.add("fill-cb-sea-green")
                right_scroll_timeline.querySelector("svg").classList.remove("fill-cb-faded-text")
            }
            if (timeline.scrollLeft <= 0) {
                left_scroll_timeline.querySelector("svg").classList.remove("fill-cb-sea-green")
                left_scroll_timeline.querySelector("svg").classList.add("fill-cb-faded-text")
            }
            else {
                left_scroll_timeline.querySelector("svg").classList.add("fill-cb-sea-green")
                left_scroll_timeline.querySelector("svg").classList.remove("fill-cb-faded-text")
            }
        }

        changeIconState()

        window.onresize = changeIconState

        timeline.addEventListener("scroll", changeIconState)
    }

    /**
     * Timeline Block Code Ends
     */

})